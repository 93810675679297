import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Link, useParams } from 'react-router-dom';

import CircularLoader from '@/components/atoms/CircularLoader';
import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import RecentArticlesColumn from '@/components/molecules/RecentArticlesColumn';
import ShareArticle from '@/components/organisms/ShareArticle';
import { animalsData } from '@/components/pages/PredictionArticle/data';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useArticleDetails from '@/hooks/useArticleDetails';
import useDocTitle from '@/hooks/useDocTitle';
import { i18n } from '@/translate/i18n';
import { capitalizeFirstLetter, toRouteParam } from '@/utils/formatters';
import remarkGfm from 'remark-gfm';

import './styles.scss';

const CONTENT_FIELDS = ['career', 'love', 'health'];

interface PredictionArticleData {
  animal?: string;
  career?: string;
  conclusion?: string;
  description?: string;
  health?: string;
  love?: string;
  years?: string;
}

const PredictionArticle = ({ title }: PageProps) => {
  const { zodiacAnimal, slug, year } = useParams();
  const formattedSlug = capitalizeFirstLetter(zodiacAnimal ?? '');

  useDocTitle(title);

  const { article, isLoading, error } = useArticleDetails(
    formattedSlug ?? '',
    true,
    { 'fields.currentYear': year },
  );

  const articleSlug = slug ?? '';
  const animalData = animalsData.find(
    (animal) => animal.title.toLowerCase() === zodiacAnimal,
  );

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">
          {i18n.t('articles.title')}
        </HeroTitleTypo>
      </PageTitle>
      <div className="prediction-article">
        <Breadcrumb
          articleBreadcrumb={{
            label: article?.articleBreadcrumb ?? '',
            path: `${toRouteParam(
              i18n.t('articles.title'),
              true,
            )}/${articleSlug}`,
          }}
          title={{
            label: zodiacAnimal ?? '',
            path: `${toRouteParam(
              i18n.t('articles.title'),
              true,
            )}/${articleSlug}/${zodiacAnimal ?? ''}`,
          }}
        />
        <div className="prediction-article__container">
          {isLoading ? (
            <CircularLoader />
          ) : (
            <div className="prediction-article__content-wrapper">
              {error || !article?.id ? (
                <Typography fontWeight="bold">
                  {i18n.t('articles.articleUnavailable')}
                </Typography>
              ) : (
                <>
                  <div className="prediction-article__title-wrapper">
                    <img
                      className="zodiac-card__icon"
                      src={animalData?.icon}
                      alt={`${zodiacAnimal ?? ''}-icon`}
                    />
                    <h1>{zodiacAnimal}</h1>
                  </div>
                  <div className="prediction-article__years">
                    {article?.years && (
                      <Typography color="red-200" fontWeight="bold">
                        {i18n.t('articles.yearOfBirth')}{' '}
                        <span>{article.years}</span>
                      </Typography>
                    )}
                  </div>
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    className="prediction-article__description"
                  >
                    {article?.description ?? ''}
                  </ReactMarkdown>
                  {CONTENT_FIELDS.map((field) => {
                    const fieldValue =
                      article?.[field as keyof PredictionArticleData];
                    if (!fieldValue || fieldValue.trim() === '') return null;

                    return (
                      <div
                        className="prediction-article__fields-wrapper"
                        key={field}
                      >
                        <h3>{i18n.t(`articles.contentFields.${field}`)}</h3>
                        <p>{fieldValue}</p>
                      </div>
                    );
                  })}
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    className="prediction-article__conclusion"
                  >
                    {article?.conclusion ?? ''}
                  </ReactMarkdown>
                  <Typography fontWeight="bold">
                    {i18n.t('articles.disclaimer')}
                  </Typography>
                </>
              )}
              <div className="prediction-article__footer">
                <Link
                  to={`${toRouteParam(i18n.t('articles.title'), true)}/${
                    slug ?? ''
                  }`}
                  className="article-card__link"
                >
                  {i18n.t('articles.backToArticle')}
                </Link>
                <ShareArticle />
              </div>
            </div>
          )}
          <RecentArticlesColumn />
        </div>
      </div>
    </AppTemplate>
  );
};

export default PredictionArticle;
