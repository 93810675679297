import { useLocation, useNavigate } from 'react-router-dom';

import { TitleTypo } from '@/components/atoms/TitleTypo';
import AnimalsCard from '@/components/molecules/AnimalsCard';
import {
  cardsData,
  carouselProps,
} from '@/components/organisms/SectionAnimals/data';
import useScreenWidth from '@/hooks/useScreenWidth';

import Carrousel from '../Carrousel';
import './styles.scss';

interface SectionAnimalsProps {
  title?: string;
  activeCardId?: number;
  type: string;
  bgColor: string;
}
const SectionAnimals = ({ title }: SectionAnimalsProps) => {
  const { isDesktop } = useScreenWidth();
  const { pathname } = useLocation();
  const animalId = pathname.split('/').pop();
  const newBasePath = pathname.split('/').slice(0, -1).join('/');
  const decodedString = decodeURIComponent(animalId || '').replace(/-/g, ' ');
  const index = cardsData.findIndex(
    ({ id }) => id.toString().toLowerCase() === decodedString.toLowerCase(),
  );
  const validIndex = index !== -1 ? index : 0;

  const navigator = useNavigate();

  const goToNextCard = () => {
    const nextIndex = validIndex + 1;
    if (nextIndex === cardsData.length) {
      const firstCard = cardsData[0];
      navigator(`${newBasePath}/${firstCard.id}`);
      return;
    }
    const nextCard = cardsData[nextIndex];
    navigator(`${newBasePath}/${nextCard.id}`, { preventScrollReset: true });
  };

  const goToPreviousCard = () => {
    const previousIndex = validIndex - 1;
    if (previousIndex === -1) {
      const lastCard = cardsData[cardsData.length - 1];
      navigator(`${newBasePath}/${lastCard.id}`, { preventScrollReset: true });
      return;
    }
    const previousCard = cardsData[previousIndex];
    navigator(`${newBasePath}/${previousCard.id}`);
  };

  return (
    <div className={'animal-carousel__container bg-color'}>
      {title && <TitleTypo color="white-900">{title}</TitleTypo>}

      <Carrousel
        {...carouselProps}
        adaptiveHeight
        count={cardsData.length}
        indicatorType={isDesktop ? 'all' : 'dots'}
        indicatorColor={'red'}
        additionalAction={[goToPreviousCard, goToNextCard]}
        inicialSlide={validIndex}
      >
        {Array.isArray(cardsData) &&
          cardsData.map((chineseAnimalsCards) => {
            const { id, ...data } = chineseAnimalsCards;
            return (
              <AnimalsCard
                key={id}
                chineseAnimalsCards={data}
                isCarousel={true}
              />
            );
          })}
      </Carrousel>
    </div>
  );
};

export default SectionAnimals;
